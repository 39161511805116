import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import Paginate from "@/components/widgets/pagination.vue";
import vSelect from 'vue-select'

const _ = require("lodash");

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  props: {
    width: {
      type: String,
      default: "w-50 m-auto",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Paginate,
    vSelect
  },
  data() {
    return {
      keyword: "",
      keyword_lot: "",
      keyword_color: null,
      keyword_thick: "",
      keyword_brand: null,
      keyword_code: "",
      totalItem: 0,
      busy: false,
      sortBy: "name",
      currentPage: 1,
      currentData: {},
      perPage: 8,
      sortDesc: false,
      fields: [
        {
          key: "selected",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
        },
        {
          key: "lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "code",
          label: "รหัสม้วน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "invoice_date",
          label: "Invoice date",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "can_pick",
          label: "สาขาสั่งได้",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาต่อกิโล/Kg",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        },
      ],
      apiStatus: "ไม่พบข้อมูล",
      items: [],
      selectedRows: [],
      orderStatus: [
        { text: "รอเข้า", value: "awaiting" },
        { text: "พร้อมสั่ง", value: "readyfororder" },
        { text: "กำลังส่ง", value: "transferring" },
        { text: "รับแล้ว", value: "delivered" },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  watch: {
    show: async function (newValue, oldValue) {
      if (newValue) {
        this.items = [];
        this.onSearchHandler(this.currentPage);
      }
      await this.loadBrandOptions();
      await this.loadSizeOptions();
      await this.loadColorOptions();
    },
  },
  methods: {
    onRowSelected(items) {
      this.selectedRows = items;
    },
    onreset() {
      this.items = [];
      this.selectedRows = [];
    },
    onClose() {
      this.onreset();
      this.$emit("onProductClose", {
        status: false,
      });
    },
    onSelected() {
      this.$emit("onProductClose", {
        status: true,
        data: this.selectedRows,
      });
    },
    onSearchHandler: async function (currentPage) {
      try {
        this.busy = true;
        this.apiStatus = "กำลังค้นหาข้อมูล..";
        const page = currentPage ? currentPage : 1;
        const url = `${
          Vue.prototype.$host
        }/api/v1/availablestock?page=${page}&perPage=8&keyword=${this.keyword}
        &keyword_lot=${this.keyword_lot}
        &keyword_color=${this.keyword_color ? this.keyword_color : ""}
        &keyword_thick=${this.keyword_thick}
        &keyword_brand=${this.keyword_brand ? this.keyword_brand : ""}
        &keyword_code=${this.keyword_code}`;
        const param = {
          url: url,
        };
        const result = await this.$store.dispatch("Products/search", param, {
          root: true,
        });
        setTimeout(() => {
          this.busy = false;
          this.apiStatus = "ไม่พบข้อมูล";
          if (result) {
            this.currentData = result.data;
            this.items = result.data.data;
            this.totalItem = result.data.total;
          } else {
            this.items = [];
            this.apiStatus = "ไม่พบข้อมูล";
            this.onExceptionHandler();
          }
        }, 500);
      } catch (error) {
        this.busy = false;
        this.apiStatus = "ไม่พบข้อมูล";
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadBrandOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/brand`
        );
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาเลือกยี่ห้อ --" }];
          this.brandOptions = starter.concat(result.data);
        }
      } catch (error) {
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadSizeOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/size`
        );
        if (result) {
          const starter = [{ uuid: "", name: "-- กรุณาระบุขนาด --" }];
          this.sizeOptions = starter.concat(result.data);
        }
      } catch (error) {
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadColorOptions() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/master/color`
        );
        if (result) {
          const starter = [{ uuid: null, name: "-- กรุณาระบุสี --" }];
          this.colorOptions = starter.concat(result.data);
        }
      } catch (error) {
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  },
};
